import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true},
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("./views/account/ResetPassword"),
    meta: {
      authRequired: false
    },
  },
  {
    path: "/account/change_password",
    name: "change_password",
    component: () => import("./views/account/ChangePassword"),
    meta: { authRequired: true},
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/campaigns",
    name: 'campaigns.index',
    meta: { authRequired: true},
    component : () => import("./views/campaigns/Index"),
  },
  {
    path: "/campaigns/ad/:ad_id",
    name: 'campaign.ad.index',
    meta: { authRequired: true},
    component : () => import("./views/campaigns/pages/AdCreative"),
  },
  {
    path: "/calls",
    name: 'calls.index',
    meta: { authRequired: true},
    component : () => import("./views/calls/Index"),
  },
  {
    path: "/tld-sales",
    name: 'tld.sales.index',
    meta: { authRequired: true},
    component : () => import("./views/tldSales/Index"),
  },
  {
    path: "/settings/users",
    name: 'users',
    meta: { authRequired: true},
    component : () => import("./views/settings/user/Index"),
  },
  {
    path: "/settings/users/create",
    name: 'users.create',
    meta: { authRequired: true},
    component : () => import("./views/settings/user/CreateUser"),
  },
  {
    path: "/settings/users/:id/edit",
    name: 'users.edit',
    meta: { authRequired: true},
    component : () => import("./views/settings/user/EditUser"),
  },
  {
    path: "/settings/roles_and_permissions",
    name: 'roles_and_permissions',
    meta: { authRequired: true},
    component : () => import("./views/settings/roles_and_permissions/Index"),
  },
  {
    path: "/settings/roles_and_permissions/:id",
    name: 'roles_and_permissions.edit',
    meta: { authRequired: true},
    component : () => import("./views/settings/roles_and_permissions/EditRolePermissions"),
  },
  {
    path: "/settings/source_accounts",
    name: 'source_accounts',
    meta: { authRequired: true},
    component : () => import("./views/settings/source_accounts/Index"),
  },
  {
    path: "/settings/verticals",
    name: 'verticals',
    meta: { authRequired: true},
    component : () => import("./views/settings/verticals/Index"),
  },
  {
    path: "/settings/verticals/add",
    name: 'verticals_add',
    meta: { authRequired: true},
    component : () => import("./views/settings/verticals/AddVertical"),
  },
  {
    path: "/settings/verticals/:id/update",
    name: 'verticals_update',
    meta: { authRequired: true},
    component : () => import("./views/settings/verticals/EditVertical"),
  },
  {
    path: "/settings/source_accounts/add",
    name: 'source_accounts.add',
    meta: { authRequired: true},
    component : () => import("./views/settings/source_accounts/AddAccount"),
  },
  {
    path: "/settings/automation/",
    name: 'automation_settings',
    meta: { authRequired: true},
    component : () => import("./views/settings/automation/Index"),
  },
  {
    path: "/settings/automation/add",
    name: 'automation_settings.add',
    meta: { authRequired: true},
    component : () => import("./views/settings/automation/settings/AddAutomation"),
  },
  {
    path: "/settings/automation/:id/update",
    name: 'automation_settings.update',
    meta: { authRequired: true},
    component : () => import("./views/settings/automation/settings/EditAutomation"),
  },
  {
    path: "/settings/automation/monitor",
    name: 'automation_settings.monitor',
    meta: { authRequired: true},
    component : () => import("./views/settings/automation/AutomationMonitor"),
  },
  {
    path: "/settings/scheduler/logs",
    name: 'settings.scheduler.logs',
    meta: { authRequired: true},
    component : () => import("./views/settings/scheduler/SchedulerLogs"),
  },
  {
    path: "/settings/data/import",
    name: 'settings.data.import',
    meta: { authRequired: true},
    component : () => import("./views/settings/data_import/DataImport"),
  },
];